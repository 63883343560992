// import { currentUser } from 'constants/defaultValues';
import {
  setCurrentLanguage,
  setCurrentCondominium,
  setCurrentUser,
} from 'helpers/Utils';
// eslint-disable-next-line import/no-cycle
import {
  CHANGE_LOCALE,
  CHANGE_CONDOMINIUM,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  ADD_USER_IMAGE,
  ADD_USER_IMAGE_SUCCESS,
  ADD_USER_IMAGE_ERROR,
  DELETE_USER_IMAGE,
  DELETE_USER_IMAGE_SUCCESS,
  DELETE_USER_IMAGE_ERROR,
} from '../actions';

// eslint-disable-next-line import/prefer-default-export
export const changeLocale = (locale) => {
  setCurrentLanguage(locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};
export const changeCondominium = (id) => {
  setCurrentCondominium(id);
  return {
    type: CHANGE_CONDOMINIUM,
    payload: id,
  };
};

export const updateUser = (newValues, authUser, history) => ({
  type: UPDATE_USER,
  payload: { newValues, authUser, history },
});
export const updateUserSuccess = (user, token) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { user, strapiToken: token },
});
export const updateUserError = (message) => ({
  type: UPDATE_USER_ERROR,
  payload: { message },
});
export const addUserImage = (imageFile, authUser, oldImage, history) => {
  // console.log(setCurrentUser(currentUser));
  return {
    type: ADD_USER_IMAGE,
    payload: { imageFile, authUser, oldImage, history },
  };
};
export const addUserImageSuccess = (user) => {
  setCurrentUser(user);
  return {
    type: ADD_USER_IMAGE_SUCCESS,
    payload: { user },
  };
};
export const addUserImageError = (message) => ({
  type: ADD_USER_IMAGE_ERROR,
  payload: { message },
});
export const deleteUserImage = (currentUser, history) => ({
  type: DELETE_USER_IMAGE,
  payload: { currentUser, history },
});
export const deleteUserImageSuccess = (history) => ({
  type: DELETE_USER_IMAGE_SUCCESS,
  payload: { history },
});
export const deleteUserImageError = (message) => ({
  type: DELETE_USER_IMAGE_ERROR,
  payload: { message },
});
export const updateUserEmail = (user, currentUser, history) => ({
  type: UPDATE_USER,
  payload: { user, currentUser, history },
});
export const updateEmailSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { user },
});
export const updateEmailError = (message) => ({
  type: UPDATE_USER_ERROR,
  payload: { message },
});
export const updatePassword = (user, currentUser, history) => ({
  type: UPDATE_USER,
  payload: { user, currentUser, history },
});
export const updatePasswordSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { user },
});
export const updatePasswordError = (message) => ({
  type: UPDATE_USER_ERROR,
  payload: { message },
});
